import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RewardService } from '../services/reward/reward.services';
import { TranslationConstants } from '../services/util/translation.constants';
import { SnackBarTheme } from '../models/snackbar-theme.enum';
import { SnackbarService } from '../services/util/snackbar.service';
import { Router } from '@angular/router';
import { NFTPackageBought } from '../models/INFTPackage';

@Component({
    selector: 'app-redeem-reward-modal',
    templateUrl: './redeem-reward-modal.component.html',
    styleUrls: ['./redeem-reward-modal.component.scss']
})
export class RedeemRewardModalComponent implements OnInit {
    public loading: boolean = false;
    public rewardCode: string = '';
    public nftSuccessClose: boolean = true;
    public nftPackageResponse: NFTPackageBought = new NFTPackageBought();

    constructor(
        private readonly dialogRef: MatDialogRef<RedeemRewardModalComponent>,
        private readonly rewardService: RewardService,
        private readonly snackbarService: SnackbarService,
        private readonly translationConstants: TranslationConstants,
        private readonly router: Router
    ) {

    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass(['custom-modal', 'custom-background']);
    }

    public close(): void {
        this.dialogRef.close();
    }

    public redeemReward(): void {
        this.loading = true;
        this.rewardService.redeemReward(this.rewardCode).subscribe(resp => {
            this.snackbarService.openSnackBar(this.translationConstants.translate('redeemRewardModal.snackbar.rewardSuccessfullyRedeemed'), SnackBarTheme.success, 4000);

            this.openNftPackageSuccess({
                package_info: Object.assign({
                    short_name: (resp as any)?.name
                }),
                nfts: [(resp as any)]
            });

            this.loading = false;
        }, error => {
            let snackbarMessage: string = '';

            if (error.error?.message?.includes('Reward not found')) {
                snackbarMessage = this.translationConstants.translate('redeemRewardModal.snackbar.rewardNotFound');
            } else if (error.error?.message?.includes('Reward already redeemed')) {
                snackbarMessage = this.translationConstants.translate('redeemRewardModal.snackbar.rewardAlreadyRedeemed');
            } else if (error.error?.message?.includes('Reward disabled')) {
                snackbarMessage = this.translationConstants.translate('redeemRewardModal.snackbar.rewardDisabled');
            } else if (error.error?.message?.includes('Redemption period expired')) {
                snackbarMessage = this.translationConstants.translate('redeemRewardModal.snackbar.redemptionPeriodExpired');
            } else {
                snackbarMessage = this.translationConstants.translate('redeemRewardModal.snackbar.failedToRedeemReward');
            }

            this.snackbarService.openSnackBar(snackbarMessage, SnackBarTheme.error, 4000);
            this.loading = false;
        });
    }

    public closeNftSuccess(event: boolean): void {
        if (event) {
            this.nftSuccessClose = true;
            this.close();
        }
    }

    public openNftPackageSuccess(packageBought: NFTPackageBought): void {
        this.nftPackageResponse = packageBought;
        this.nftSuccessClose = false;
    }
}
