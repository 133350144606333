import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { File } from '../../models/file.model';
import { ErrorHandlerService } from './error.handler.service';
import { FileStorageConfidentiality, StorageObject, StorageObjectContent } from 'src/app/shared/models/file.model';

/**
 * File service
 */
@Injectable()
export class FileService {

    constructor(
        private readonly http: HttpClient,
        private readonly errorHandler: ErrorHandlerService
    ) {
    }

    /**
     * Upload a file
     * @param file File to be uploaded to s3
     */
    public uploadFile(file: File): Observable<any> {
        const header: HttpHeaders = new HttpHeaders({ });
        return this.http.post<any>(RestEndpoint.files, file, {headers: header})
            .pipe(
                catchError((err) => { return this.errorHandler.validate(err); })
            );
    }

     /**
     * This method returns the attached file name in Normalization Form Unicode, assigning its code point to each character, 
     * ensuring its mapping and final representation, that is, converting the string into normalized Unicode format.
     * NFD - Normalization Form Canonical Decomposition - Will keep accented characters separated by their canonical equivalence.
     * E.g.: Letter with accent 'Á' represented by its unicode ('U+0061' + 'U+0301'), Letter 'O' ('U+006F'), Letter 'I' ('U+0069')...
     * The check is performed on the range of accented characters '\u0300-\u036f', numbering '0-9', lowercase letters 'a-z', uppercase letters 'A-Z'.
    */
      public normalizeFileName(fileName: string): string{
        return fileName.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z][\-\_])/g, '');
    }

    /**
     * Retrieve a file data
     * @param fileName File to be downloaded from storage
     */
    public getSecuredDocumentUrl(fileName: string): Observable<string> {
        return this.http.get(RestEndpoint.files, { params: {fileName: fileName, confidentiality: FileStorageConfidentiality.SECURED }})
            .pipe(
                map((storageObject: StorageObject) => {
                    const storageObjectContent: StorageObjectContent =  JSON.parse(storageObject?.value?.image)
                    const contentType: string = storageObjectContent.ContentType;
                    const file: string = Buffer.from(storageObjectContent.Body?.data).toString("base64")
                    //see the documentation at https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
                    const url: string = 'data:' + contentType + ';base64,' + file;
                    return url;
                }),
                catchError((err) => {
                    throw (err);
                })
            );  
    }
}