import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CustomerBalance } from '../../models/customer.balance';
import { AssetsSearchInput, AssetsSearchReport } from '../../models/AssetsSearchInput.model';
import { Assets, ResellAsset } from '../../models/IAssets.model';
import { ReportGeneralJournal } from '../../models/report.general.journal';
import { MarketJournal, MarketJournalInput } from '../../models/market-journal.model';
import { FinanceResponse } from '../../models/finance.response';
import BigNumber from 'bignumber.js';
import { Router } from '@angular/router';
import { IMidasWebBanners } from '../../models/IMidasWebBanners.model';
import { IEniatoBanners } from '../../models/IEniatoBanners.model';
import { NFTPackage } from '../../models/INFTPackage';
import { IProjectCrowdfunding } from '../../models/IProjectCrowdfunding.model';
import { CrowdfundingCategories } from '../../models/crowdfunding.categories';
import { ICrowdfundingTermsAccepted } from 'src/app/tokenization/models/crowdfunding.terms.accepted';
import { ICrowdfundingOrders } from 'src/app/tokenization/models/crowdfunding.orders';
import { ICrowfundingBankingAccount } from 'src/app/tokenization/models/ICrowfundingBankingAccount';

@Injectable()
export class AssetsService {

    /**
     * Stores the user to be resetted
     */
    public userEmail: string;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router

    ) {
    }

    public searchAssetCategory(category: string): void {
        this.router.navigate(['category/' + category]);
    }

    public createAssets(request: any): Observable<any> {
        return this.http.post<any>(RestEndpoint.createAsset, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public sendNft(request: {
        customerIdToTransferTo: string,
        unitOfMoney: string,
        assetId: string,
        nftId?: string,
        blockchainId?: string
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.sendNFTs, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public buyNFTs(request: {
        unitOfMoney: string,
        assetId: string,
        nftId: string, //Used when a NFT was already bought 
        blockchainId: string, //Used when a NFT was already bought 
        hash: string,
        tax: string,
        wantedPrice: BigNumber,
        principalAmount: number
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.buyNFT, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public buyPackage(request: {
        unitOfMoney: string,
        assetId: string,
        tax: string,
        hash: string,
        principalAmount: number
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.buyPackage, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getPackageTypes(): Observable<Array<string>> {
        return this.http.get(RestEndpoint.getPackageTypes, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public editAssets(request: any): Observable<any> {

        return this.http.put<any>(RestEndpoint.editAsset, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getEniatoBalance(): Observable<CustomerBalance> {
        return this.http.get(RestEndpoint.getEniatoBalance, {})
            .pipe(
                map((data: CustomerBalance) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBalance(): Observable<CustomerBalance> {
        return this.http.get(RestEndpoint.balance, {})
            .pipe(
                map((data: any) => {
                    return data?.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createMarket(request: MarketJournalInput): Observable<FinanceResponse> {
        return this.http.post<any>(RestEndpoint.createMarket, request)
            .pipe(
                map((userData: FinanceResponse) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    //Source might be NFT or ASSET, it will determine which type of id is being provided
    //It might be used NFT when it is about a NFT which was already bougth by someone
    //It happens because whenever a NFT is bougth it became unique in the blockchain, and this information is stored in a different table
    //So, it must to be indicated from which one it needs to be retrieved
    public getAssetDetails(assetIdentifier?: string, source: string = 'ASSET'): Observable<Assets | NFTPackage> {

        return this.http.get(RestEndpoint.getDetails, { params: {
            assetIdentifier,
            source
        } })
            .pipe(
                map((data: Assets | NFTPackage) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getReservationsForAsset(assetId?: string): Observable<number> {

        return this.http.get(RestEndpoint.getCountForAssets, { params: {
            assetId
        } })
            .pipe(
                map((data: any) => {
                    return data?.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getCrowdfundings(): Observable<IProjectCrowdfunding[]> {

        return this.http.get(RestEndpoint.getCrowdfundings)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getCrowdfundingCompanyDetails(projectId: string): Observable<any> {
        return this.http.get(RestEndpoint.getCrowdfundingCompanyDetails, {params: {projectId}})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public getCrowdfundingCategories(): Observable<Array<CrowdfundingCategories>> {
        return this.http.get(RestEndpoint.getAllCrowdfundingCategories, {})
            .pipe(
                map((data: Array<CrowdfundingCategories>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getCrowdfunding(crowdfundingId: string): Observable<IProjectCrowdfunding> {

        return this.http.get(RestEndpoint.getCrowdfunding, {
            params: {
                crowdfundingId
            }
        })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public updateUserAcceptedTerms(object: ICrowdfundingTermsAccepted): Observable<string> {

        return this.http.post(RestEndpoint.updateUserAcceptedTerms, object)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createCrowdfundingCheckoutOrder(object: ICrowdfundingOrders): Observable<string> {

        return this.http.post(RestEndpoint.createCrowdfundingCheckoutOrder, object)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public performPaymentWithCrypto(object: any): Observable<string> {

        return this.http.post(RestEndpoint.performPaymentWithCrypto, object)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllUserOders(): Observable<ICrowdfundingOrders[]> {

        return this.http.get(RestEndpoint.getAllUserOders)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllCrowdfundingBanking(projectId: string): Observable<ICrowfundingBankingAccount[]> {

        return this.http.get(RestEndpoint.getAllCrowdfundingBanking, { params: {
            projectId
        } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAssets(search?: AssetsSearchInput): Observable<AssetsSearchReport> {
        let params = new HttpParams();
        if (search) {
            for (const key of Object.keys(search)) {
                if (search[key]) {
                    params = params.set(key, Array.isArray(search[key]) ? search[key].join(',') : search[key]);
                }
            }
        }

        return this.http.get(RestEndpoint.getAssets, { params })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMarketTransactions(assetId?: string): Observable<Array<MarketJournal>> {

        return this.http.get(RestEndpoint.markets, { params: {assetId }})
            .pipe(
                map((data: any) => {
                    return data?.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserTransactions(assetId?: string): Observable<ReportGeneralJournal> {

        return this.http.get(RestEndpoint.getUserTransactions)
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public commentItem(itemComment: any): Observable<any>
    {
        return this.http.post<any>(RestEndpoint.createComment, itemComment)
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getAsset(assetIdentifier?: string): Observable<Assets> {

        return this.http.get(RestEndpoint.getAsset, { params: {assetIdentifier }})
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getHistory(assetIdentifier?: string): Observable<Assets> {

        return this.http.get(RestEndpoint.getAsset, { params: {assetIdentifier }})
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getHttpParamsFromObject(object: any): HttpParams {
        let params = new HttpParams();
        for (const key of Object.keys(object)) {
            if (object[key]) {
                params = params.set(key, object[key]);
            }
        }
        return params;
    }

    public getCategories(): Observable<any> {
        return this.http.get(RestEndpoint.getCategories, {})
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBanners(): Observable<Array<IEniatoBanners>> {
        return this.http.get(RestEndpoint.getAllMidasBanners, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getEniatoBanners(): Observable<Array<IEniatoBanners>> {
        return this.http.get(RestEndpoint.getAllEniatoBanners, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllCrowdfundingBanners(): Observable<Array<IEniatoBanners>> {
        return this.http.get(RestEndpoint.getAllCrowdfundingBanners, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getExchangeBanners(): Observable<Array<IEniatoBanners>> {
        return this.http.get(RestEndpoint.getAllExchangeBanners, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllPaymentQueuesTimelinesView(): Observable<Array<any>> {
        return this.http.get(RestEndpoint.getAllPaymentQueuesTimelinesView, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public resellAsset(request: ResellAsset): Observable<boolean> {
        return this.http.post<ResellAsset>(RestEndpoint.resellAsset, request)
        .pipe(
            map((output: any) => {
                return output;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public getAssetTransactionTaxPrice(asset: any, isResaleAllowed: boolean): number {
        let taxValue: number = 0;

        if (asset?.taxType && asset?.transactionTax) {
            if (asset?.taxType === 'A') {
                taxValue = asset?.transactionTax;
            } else {
                let assetPrice = new BigNumber(0);

                if (isResaleAllowed && asset?.resale?.onResale) {
                    assetPrice = new BigNumber(asset?.resale?.resaleValue);
                } else {
                    assetPrice = new BigNumber(asset?.price);
                }

                taxValue = assetPrice.multipliedBy(new BigNumber(asset?.transactionTax)).dividedBy(100).toNumber();
            }
        }

        return taxValue;
    }

    public getAssetCorrectPrice(asset: any, isResaleAllowed: boolean, sumTax: boolean = false): number {
        let nftTransactionTax: BigNumber = new BigNumber(0);
        if (asset?.taxType && asset?.transactionTax) {
            if (asset.taxType === 'A') {
                nftTransactionTax = new BigNumber(asset?.transactionTax);
            } else {
                nftTransactionTax = new BigNumber(asset?.price).multipliedBy(new BigNumber(asset?.transactionTax)).dividedBy(100);
            }
        }

        if (isResaleAllowed && asset?.resale?.onResale) {
            return new BigNumber(asset.resale.resaleValue)?.plus(sumTax ? nftTransactionTax : 0)?.toNumber();
        }

        return new BigNumber(asset?.price)?.plus(sumTax ? nftTransactionTax : 0)?.toNumber();
    }

    public getAssetOriginalPrice(asset: any, isResaleAllowed: boolean): number {
        return new BigNumber(asset?.originalPrice || asset?.price).toNumber();
    }

    public getAssetMainPhoto(asset: any): string {
        if (!asset) {
            return;
        }

        let photoToReturn: string = '';
        if (asset?.url) {
            photoToReturn = asset?.url;
        }else {
            for (let photo of asset?.photos) {
                if (photo.order <= 0)
                {
                    photoToReturn = photo.url;
                    break;
                }
            }
        }

        if (!photoToReturn) {
            return "/assets/card-asset/token-image.webp";
        }
        return photoToReturn;
    }

    public createAuction(nftId: string, minimumPrice: BigNumber, deadline: Date): Observable<any> {
        return this.http.post<any>(RestEndpoint.createAuction, {
            nftId: nftId,
            minimumPrice: minimumPrice,
            deadline: deadline
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public createSplit(nftId: string, parts: any): Observable<any> {
        return this.http.post<any>(RestEndpoint.splitNFTs, {
            id: nftId,
            parts: parts
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public createBid(auctionId: string, price: BigNumber, unitOfMoney: string): Observable<any> {
        return this.http.post<ResellAsset>(RestEndpoint.createBid, {
            params: {
                auctionId: auctionId,
                price: price,
                unitOfMoney: unitOfMoney
            }
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public acceptHigherBid(auctionId: string): Observable<any> {
        return this.http.post<ResellAsset>(RestEndpoint.acceptHigherBid, {
            params: {
                auctionId: auctionId
            }
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public finishAuction(auctionId: string): Observable<any> {
        return this.http.post<ResellAsset>(RestEndpoint.finishAuction, {
            params: {
                auctionId: auctionId
            }
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public cancelAuction(auctionId: string): Observable<boolean> {
        return this.http.post<boolean>(RestEndpoint.cancelAuction, {
            params: {
                auctionId: auctionId
            }
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public cancelMyBid(bidId: string): Observable<any> {
        return this.http.post<any>(RestEndpoint.cancelMyBid, {
            params: {
                bidId: bidId
            }
        })
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }
}
