import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { FeatureNames } from '../models/feature-names.enum';
import { FeaturesStatusService } from '../services/util/features-status.service';

@Component({
    selector: 'app-nft-package-success',
    templateUrl: './nft-package-success.component.html',
    styleUrls: ['./nft-package-success.component.scss']
})
export class NftPackageSuccessComponent implements OnInit, OnDestroy {

    public packageSize: number;
    @Input() public nfts: Array<Assets>;
    @Input() public packageName: string = '';
    @Output() public closeAnimation: EventEmitter<boolean> = new EventEmitter;

    public nftContainer: any;
    public showVideo: boolean = false;
    public videoName: string = 'assets/NFTPackage/buyingEffect.mp4';
    public DELAY_ENABLE_HOVER_CARD: number = 14500; // milliseconds
    public enableHoverCard: boolean = false;
    public loading: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly configService: FeaturesStatusService,
        private readonly renderer: Renderer2
    ) { }

    public ngOnInit(): void {
        this.loading = true;
        this.configService.getFeatureStatus(FeatureNames.NFT_VIDEO).subscribe( showVideo => {
            this.showVideo = showVideo;
        }).add(() => {
            this.loading = false;
        })

        this.packageSize = this.nfts?.length || 2;
        document.querySelector('html')?.classList.add('hide-scroll');

        setTimeout(() => {
            this.renderer.addClass(document.body, 'no-header-footer');
        }, 2000)
        
        // Enable hover effect when all animations are stopped
        setTimeout(() => this.enableHoverCard = true, this.DELAY_ENABLE_HOVER_CARD);
    }

    public ngOnDestroy(): void {
        document.querySelector('html')?.classList.remove('hide-scroll');
        this.renderer.removeClass(document.body, 'no-header-footer');
    }

    public closeNftPackageSuccess(): void {
        setTimeout(() => {
            this.router.navigate(['/tokenization/my-assets']);
            this.closeAnimation.emit(true)
        }, 100);
    }

    public setCurrentTime(time: number): void {
        if (time > 7) {
            this.showVideo = false;
        } else {
            this.showVideo = true;
        }
    }
}
