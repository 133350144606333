<div class="video-container" *ngIf="showVideo && !loading">
    <video class="video" #video (canplay)="video.play()" (timeupdate)="setCurrentTime($event.target.currentTime)">
        <source [src]="videoName" type="video/mp4">
        Browser not supported
    </video>
</div>

<div *ngIf="!loading" class="background column" [ngClass]="{'start-animation': !showVideo}" (click)="closeNftPackageSuccess()">
    <div class="inline-top">
        <h1 class="title">
            {{ 'paymentModal.nftSuccess.youBought' | translate }}
        </h1>
        <mat-icon class="close" >close</mat-icon>
    </div>
    
    <div class="NFTs" id="NFTs">
        <drag-scroll class="hiddenScroll" style="height: 100%; display: flex;" [drag-scroll-y-disabled]="true">
            <div class="card-divider">.</div>
            <div class="card-container" *ngFor="let nft of nfts; index as i" [ngClass]="[enableHoverCard ? 'enable-hover' : '', i === 0 ? 'first-card' : '']">
                <div class="card frontface" [ngClass]="nft?.nft_package_type">

                    <div class="card border column">
                        <div class="asset-image">
                            <img [src]="nft?.photos[0]?.url" [alt]="nft?.photos[0]?.name">
                        </div>
                        <div class="info column">
                            <span class="title-small"> {{ nft?.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="card backface" [ngClass]="nft?.nft_package_type"></div>
            </div>
            <div class="card-divider">.</div>
        </drag-scroll>
    </div>
</div>
