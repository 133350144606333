import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { AccountBalance } from "../../models/account.balance";
import { IEscrowResponse } from "../../models/escrow.model";
import { IInvoiceResponse } from "../../models/invoice.model";
import { Token } from "../../models/tokens";

@Injectable({
    providedIn: 'root'
})
export class TokensService {

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router
    ) { }

    public getTokens(): Observable<Array<Token>> {
        return this.http.get(RestEndpoint.getAllTokens, {})
            .pipe(
                map((data: Array<Token>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMainBallance(): Observable<Array<AccountBalance>> {
        return this.http.get(RestEndpoint.mainWalletBalance, {})
            .pipe(
                map((data: Array<AccountBalance>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public nextWihdrawal(): Observable<any> {
        return this.http.get(RestEndpoint.nextWihdrawal, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public remainingTokenAmount(): Observable<any> {
        return this.http.get(RestEndpoint.remainingTokenAmount, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public withdrawTokens(): Observable<any> {
        return this.http.post(RestEndpoint.withdrawTokens, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public searchToken(filter: Token): Observable<Array<Token>> {
        return this.http.post(RestEndpoint.searchTokens, filter)
            .pipe(
                map((data: Array<Token>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getTokenById(tokenIdentifier: string): Observable<Token> {
        return this.http.get(RestEndpoint.getToken, { params: { tokenIdentifier } })
            .pipe(
                map((data: Token) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public deleteToken(id: string): Observable<boolean> {
        const url = RestEndpoint.deleteToken + id;
        return this.http.delete<boolean>(url)
            .pipe(
                catchError((err) => {
                    throw err;

                })
            );
    }


    public saveToken(newParameter: Token): Observable<boolean> {
        return this.http.put<boolean>(RestEndpoint.updateTokens, newParameter)
            .pipe(
                map((updated: boolean) => {
                    return updated;
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public createToken(Token: Token): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createToken, Token)
            .pipe(
                map((tokenId: string) => {
                    if (tokenId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }


    /**
     * @param productId The product tha will be change the favorite
     * @param addFavorite If you are going to add the favorite
     * @param redirectUrl Route to go after logging in (if user was not logged in)
     * @returns Returns if the operation was successful
     */
    public toggleFavoriteToken(productId: string, addFavorite?: boolean, redirectUrl?: string): Observable<boolean> {
        let toggleFavoriteSuccess: boolean = false;
        return this.http.post<boolean>( RestEndpoint.isAuthenticated, { })
        .pipe(
            map((authenticated: any) => {
                if ( !authenticated || !authenticated.isAuthenticated)
                {
                    this.router.navigate(['/account/login'], {
                        queryParams: {
                            redirectUrl: redirectUrl,
                        }
                    });
                    toggleFavoriteSuccess = false;
                    return toggleFavoriteSuccess;
                } else {
                    if (addFavorite) {
                        this.createFavorites(productId).subscribe();
                    } else {
                        this.deleteFavorites(productId).subscribe();
                    }
                    toggleFavoriteSuccess = true;
                    return toggleFavoriteSuccess;
                }
            })
        );
    }

    public deleteFavorites(tokenId: string): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.updateTokenFavorite, { tokenId: tokenId })
            .pipe(
                map((templateId: string) => {
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public createFavorites(tokenId: string): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createTokenFavorite, { tokenId: tokenId })
            .pipe(
                map((templateId: string) => {
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public getEscrows(): Observable<Array<IEscrowResponse>> {
        return this.http.get(RestEndpoint.getEscrows, {})
            .pipe(
                map((data: {data: Array<IEscrowResponse>}) => {
                    return data?.data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getInvoices(): Observable<Array<IInvoiceResponse>> {
        return this.http.get(RestEndpoint.getInvoices, {})
            .pipe(
                map((data: {data: Array<IInvoiceResponse>}) => {
                    return data?.data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getExternalDeposits(): Observable<Array<{
        "userId": string,
        "fiatAmount": number,
        "id": string,
        "tokensAmount": number,
        "depositMethod": string,
        "depositAddress": string,
        "name": string,
        "identity": string,
        "email": string,
        "status": string,
        "when": Date
    }>> {
        return this.http.get(RestEndpoint.getExternalDeposits, {})
            .pipe(
                map((data: {data: Array<any>}) => {
                    return data?.data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}