<div class="redeem-reward-modal modal">
    <div class="modal-header">
        <h1>{{ 'redeemRewardModal.title' | translate }}</h1>
        <mat-icon class="close" mat-dialog-close>close</mat-icon>
    </div>

    <div class="wrapper-hint">
        <label class="input-label">{{ 'redeemRewardModal.label' | translate }}</label>
        <input class="input" [(ngModel)]="rewardCode" type="text" maxlength="50"
            [placeholder]="'placeholder.insert' | translate">
    </div>

    <div class="modal-buttons">
        <button (click)="redeemReward()" [disabled]="!rewardCode || loading" mat-flat-button class="btn default">{{'redeemRewardModal.button' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>

<!-- When start the NFT package animation -->
<app-nft-package-success *ngIf="!nftSuccessClose" (closeAnimation)="closeNftSuccess($event)" [packageName]="nftPackageResponse?.package_info?.short_name" [nfts]="nftPackageResponse?.nfts"></app-nft-package-success>
