import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RewardService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public redeemReward(rewardCode: string): Observable<any> {
        return this.http.post<any>(RestEndpoint.redeemReward, {rewardCode})
            .pipe(
                map((resp: any) => {
                    return resp;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }
}
